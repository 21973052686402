import AsyncStorage from '@react-native-async-storage/async-storage';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { useFocusEffect, useNavigation, useScrollToTop } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Axios from 'axios';
import { Camera } from 'expo-camera';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as FileSystem from 'expo-file-system';
import * as Network from 'expo-network';
import * as Notifications from 'expo-notifications';
import moment from 'moment';
import 'moment-timezone';
import React, { Dispatch, Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Platform, RefreshControl, SafeAreaView, ScrollView, StatusBar, StyleSheet, View } from 'react-native';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Chronometer from '../../components/Chronometer';
import H1 from '../../components/H1';
import HomeWorking from '../../components/HomeWorking';
import NextShift from '../../components/NextShift';
import Pictures from '../../components/Pictures';
import SIButton from '../../components/SIButton';
import SIText from '../../components/SIText';
import ShiftCards from '../../components/ShiftCards';
import Tag from '../../components/Tag';
import Tools from '../../components/Tools';
import { ActionType, InitialState } from '../../store';
import { checkLastClockingsWithCurrentClocking } from '../../store/actions/clockingActions';
import colors from '../../styles/colors';
import { IScanCode } from '../../types/clocking.model';
import { IDepartment } from '../../types/department.model';
import { IShift } from '../../types/shift.model';
import { generateOptimisticId, getContrast, minutesToHoursAndOrMinutes, nowUnix } from '../../utils';
import ClockingAddTask from './AddTaskScreen';
import CheckOutScreen from './CheckOutScreen';
import OnlyClockin from './OnlyClockin';
import TaskEditScreen from './TaskEditScreen';
import TaskFlowScreen from './TaskFlowScreen';
import NoOfflineDepartmentWarning from './components/NoOfflineDepartmentWarning';
import OfflineClockingsWarning from './components/OfflineClockingsWarning';
import Overview from './components/Overview';
import Pauses from './components/Pauses';
import Tasks from './components/Tasks';
moment.tz.setDefault('Atlantic/Reykjavik');

const Stack = createStackNavigator();

const HomeStack: React.FC = () => {
  const { t } = useTranslation(undefined, { useSuspense: false });
  return (
    <Stack.Navigator>
      <Stack.Screen name="Main" component={HomeScreen} options={{ headerShown: false }} />
      <Stack.Screen name="ClockingAddTask" component={ClockingAddTask} options={{ headerShown: false }} />
      <Stack.Screen
        name="ClockingTaskEdit"
        component={TaskEditScreen}
        options={{ headerBackTitle: t('GENERAL.BACK'), title: t('GENERAL.MODIFY') }}
      />
      <Stack.Screen
        name="ClockingTaskFlow"
        component={TaskFlowScreen}
        options={{ headerBackTitle: t('GENERAL.BACK'), title: t('CLOCKING.END_TASK') }}
      />
      <Stack.Screen name="ClockingCheckOut" component={CheckOutScreen} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
};

type NavigationParams = {
  HomeScreen: {
    qrcode?: IScanCode;
    refresh?: IScanCode;
    cancelQrCode?: boolean;
  };
};

type Props = BottomTabScreenProps<NavigationParams, 'HomeScreen'>;

const HomeScreen: React.FC<Props> = ({ route }) => {
  const navigation = useNavigation();
  const [filteredShifts, setFilteredShifts] = useState<IShift[]>([]);
  const [nextPlannedShifts, setNextPlannedShifts] = useState<IShift[] | null>(null);
  const [nextOpenShifts, setNextOpenShifts] = useState<IShift[] | null>(null);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const reduxDispatch: Dispatch<ActionType> = useDispatch();
  const ref = React.useRef(null);
  useScrollToTop(ref);
  const clockingState = useSelector((store: InitialState) => store.clocking, shallowEqual);
  const offlineSate = useSelector((store: InitialState) => store.offline, shallowEqual);
  const dataState = useSelector((store: InitialState) => store.data, shallowEqual);
  const authState = useSelector((store: InitialState) => store.auth, shallowEqual);
  const [dataAreLoading, setDataAreLoading] = useState<boolean>(false);
  const { shifts, indicators } = dataState;
  const currentClocking = clockingState?.currentClocking;
  const pictures = currentClocking?.pictures || [];
  const tools = currentClocking?.tools || [];
  const pauses = currentClocking?.pauses || [];
  const [activeDepartment, setActiveDepartment] = useState<IDepartment | null>(null);
  const [unixLocalTs, setUnixLocalTs] = useState<number>(nowUnix());
  const isOnline = !!offlineSate?.online;

  useFocusEffect(
    useCallback(() => {
      if (ref.current) {
        (ref.current! as any).scrollTo({ x: 0, y: 0, animated: false });
      }
      if (unixLocalTs < nowUnix() - 300) {
        setUnixLocalTs(nowUnix());
      }
    }, []),
  );

  useEffect(() => {
    if (shifts && shifts.length) {
      const startOfDay = moment.unix(unixLocalTs).startOf('day').unix();
      const filteredShifts: IShift[] = [];
      const nextPlannedShifts: IShift[] = [];
      const nextOpenShifts: IShift[] = [];

      for (let i = 0; i < shifts.length; i++) {
        const shift = shifts[i];
        if (unixLocalTs < shift.end!) {
          filteredShifts.push(shift);
          if (!shift.userRecordId) {
            nextOpenShifts.push(shift);
            continue;
          }
        }

        if (shift.userRecordId && nextPlannedShifts.length < NBR_OF_MAX_PLANNED_SHIFTS) {
          if (shift.start! > startOfDay || shift.end! > startOfDay) {
            nextPlannedShifts.push(shift);
          }
        }
      }

      setFilteredShifts(filteredShifts);
      setNextPlannedShifts(nextPlannedShifts);
      setNextOpenShifts(nextOpenShifts);
    } else {
      setFilteredShifts([]);
      setNextPlannedShifts([]);
      setNextOpenShifts([]);
    }
  }, [shifts, unixLocalTs]);

  useEffect(() => {
    if (route.params) {
      const { qrcode } = route.params;
      if (qrcode && currentClocking) {
        reduxDispatch({
          type: 'CLOCKING_ADD_CURRENT_TOOL',
          payload: {
            ...qrcode,
            time: moment().unix(),
          },
        });
      }
    }
  }, [route]);

  useEffect(() => {
    if (currentClocking) {
      const department = dataState.departments.find((department) => department.id === currentClocking.departmentId)!;
      setActiveDepartment(department);
    }
  }, [currentClocking?.departmentId]);

  const refreshData = async () => {
    if (offlineSate.busy || offlineSate.outbox?.length) {
      if (offlineSate.outbox && offlineSate.outbox.length > 0) {
        if (offlineSate.outbox[offlineSate.outbox.length].type !== 'REFRESH_DATA') {
          setTimeout(() => {
            refreshData();
          }, 2000);
        }
      }
      return;
    }
    if (!dataAreLoading) {
      try {
        setDataAreLoading(true);
        const expoPushToken = await AsyncStorage.getItem('expo-token');
        const { status } = await Notifications.getPermissionsAsync();

        Axios.get(
          `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/datas`,
          {
            params: {
              userMainId: authState.userDetails?.id,
              mobile: true,
              sections: true,
              tasks: true,
              skills: true,
              notifications: status,
              platform: dataState.platform,
              version: dataState.version,
              brand: dataState.brand,
              modelName: dataState.modelName,
              os: dataState.os,
            },
            headers: expoPushToken
              ? {
                  'x-expo-token': expoPushToken,
                }
              : undefined,
          },
        )
          .then((response) => {
            const {
              departments,
              shifts,
              lastClockings,
              currentClockings,
              signaturesRequired,
              userDetails,
              clockinAccount,
              oncall,
              indicators = [],
              swapShiftsRequests,
              features,
            } = response.data;
            checkLastClockingsWithCurrentClocking(lastClockings || [], clockingState, isOnline, reduxDispatch);
            reduxDispatch({ type: 'SIGNATURE_SET_REQUIRED_SIGNATURES', payload: signaturesRequired });
            reduxDispatch({ type: 'AUTH_UPDATE_USER_DETAILS', payload: userDetails });
            reduxDispatch({
              type: 'DATA_SET_DATA',
              payload: {
                departments,
                shifts,
                currentClockings,
                clockinAccount,
                oncall,
                indicators,
                swapShiftsRequests,
                features,
                userDetails,
              },
            });
            setDataAreLoading(false);
          })
          .catch((error) => {
            reduxDispatch({ type: 'CLOCKING_SET_BACKGROUND_RELOAD', payload: false });
            setDataAreLoading(false);
            console.log(error);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onCheckOut = () => {
    const tasks = currentClocking?.tasks;
    if (tasks && tasks.length) {
      if (tasks.some((task) => !task.end)) {
        if (Platform.OS === 'web') {
          alert(`${t('CLOCKING.ALERTS.ONGOING_TASK')}. ${t('CLOCKING.ALERTS.MUST_COMPLETE_CURRENT_TASKS')}`);
        }
        Alert.alert(t('CLOCKING.ALERTS.ONGOING_TASK'), t('CLOCKING.ALERTS.MUST_COMPLETE_CURRENT_TASKS'));
        return;
      }
    }
    navigation.navigate('ClockingCheckOut');
  };

  const onStartPause = async () => {
    const pauseOptimisticId = generateOptimisticId();
    const clockingId = currentClocking?.id || '$id';
    const network = await Network.getNetworkStateAsync();
    const { granted } = await Camera.getCameraPermissionsAsync();
    const expoPushToken = await AsyncStorage.getItem('expo-token');
    const { status } = await Notifications.getPermissionsAsync();

    reduxDispatch({
      type: 'CLOCKING_START_PAUSE',
      payload: {
        id: pauseOptimisticId,
        start: nowUnix(),
      },
      meta: {
        wasOnline: isOnline,
        offline: {
          effect: {
            method: 'PATCH',
            url: `${
              Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
            }/v3/clockings/${clockingId}?mobile=true`,
            data: {
              mobile: true,
              os: Platform.OS,
              pauses: [
                ...currentClocking!.pauses!,
                {
                  id: pauseOptimisticId,
                  start: nowUnix(),
                },
              ],
              infos: {
                version: `${Constants.manifest?.version || Constants.manifest2?.extra?.expoClient?.version}`,
                os: Platform.OS,
                model_name: Device.modelName,
                online: isOnline,
                camera_granted: granted,
                total_disk_space: Platform.OS !== 'web' ? `${await FileSystem.getTotalDiskCapacityAsync()}` : null,
                free_disk_space: Platform.OS !== 'web' ? `${await FileSystem.getFreeDiskStorageAsync()}` : null,
                ram: Device.totalMemory ? `${Device.totalMemory}` : null,
                token: authState.accessToken,
                notifications: status,
              },
            },
            optimisticId: currentClocking!.optimisticId,
          },
        },
      },
    });
  };

  const requestEndPause = async () => {
    const pauses = [...currentClocking!.pauses!];
    const clockingId = currentClocking?.id || '$id';
    const now = nowUnix();
    pauses[pauses.length - 1] = {
      ...pauses[pauses.length - 1],
      end: now,
    };
    const network = await Network.getNetworkStateAsync();
    const { granted } = await Camera.getCameraPermissionsAsync();
    const expoPushToken = await AsyncStorage.getItem('expo-token');
    const { status } = await Notifications.getPermissionsAsync();

    reduxDispatch({
      type: 'CLOCKING_END_PAUSE',
      payload: now,
      meta: {
        wasOnline: isOnline,
        offline: {
          effect: {
            method: 'PATCH',
            url: `${
              Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
            }/v3/clockings/${clockingId}?mobile=true`,
            data: {
              pauses,
              mobile: true,
              os: Platform.OS,
              infos: {
                version: `${dataState.version}`,
                os: Platform.OS,
                model_name: Device.modelName,
                online: isOnline,
                camera_granted: granted,
                total_disk_space: Platform.OS !== 'web' ? `${await FileSystem.getTotalDiskCapacityAsync()}` : null,
                free_disk_space: Platform.OS !== 'web' ? `${await FileSystem.getFreeDiskStorageAsync()}` : null,
                ram: Device.totalMemory ? `${Device.totalMemory}` : null,
                token: authState.accessToken,
                notifications: status,
              },
            },
            optimisticId: currentClocking!.optimisticId,
          },
        },
      },
    });
  };

  const onEndPause = () => {
    if (Platform.OS === 'web') {
      const confirmation = confirm(
        `${t('SHIFTS.ALERTS.SHIFT_ENDING_PAUSE_CONFIRMATION_TITLE')}. ${t(
          'SHIFTS.ALERTS.SHIFT_ENDING_PAUSE_CONFIRMATION_MESSAGE',
        )}`,
      );
      if (confirmation === true) {
        requestEndPause();
      }
    } else {
      Alert.alert(
        t('SHIFTS.ALERTS.SHIFT_ENDING_PAUSE_CONFIRMATION_TITLE'),
        t('SHIFTS.ALERTS.SHIFT_ENDING_PAUSE_CONFIRMATION_MESSAGE'),
        [
          {
            text: t('GENERAL.CANCEL'),
            style: 'cancel',
          },
          {
            text: t('GENERAL.CONFIRM'),
            onPress: requestEndPause,
          },
        ],
      );
    }
  };

  if (currentClocking && activeDepartment) {
    let currentPause = null;
    const pausesLength = pauses.length;

    if (pausesLength) {
      const lastPause = pauses[pausesLength - 1];
      if (!lastPause.end) {
        currentPause = lastPause;
      }
    }

    const { scanCodes, tasks } = activeDepartment;

    const ClockingDetails = () => (
      <View style={{ backgroundColor: colors.blueExtraLight }}>
        <View>
          {!!activeDepartment?.modules?.tasks && !!tasks?.length && (
            <View
              style={{
                marginVertical:
                  activeDepartment &&
                  activeDepartment.clockinParams &&
                  activeDepartment.clockinParams.enableMobileClockin &&
                  tasks.length > 0
                    ? 10
                    : 0,
              }}
            >
              <Tasks
                tasks={currentClocking.tasks || []}
                currentClocking={currentClocking}
                activeDepartment={activeDepartment}
                shifts={shifts}
              />
            </View>
          )}
          {activeDepartment && activeDepartment.clockinParams && activeDepartment.clockinParams.enableMobileClockin && (
            <View style={{ marginTop: 15 }}>
              <Pictures
                pictures={[...pictures].reverse()}
                addActionType="CLOCKING_ADD_CURRENT_PICTURE"
                removeActionType="CLOCKING_REMOVE_CURRENT_PICTURE"
              />
            </View>
          )}
          {!!activeDepartment?.modules?.resources &&
            Platform.OS !== 'web' &&
            scanCodes &&
            !!scanCodes?.attributes?.length &&
            activeDepartment &&
            activeDepartment.clockinParams &&
            activeDepartment.clockinParams.enableMobileClockin && (
              <View style={{ marginTop: 15 }}>
                <Tools
                  tools={[...tools].reverse()}
                  department={activeDepartment}
                  removeActionType="CLOCKING_REMOVE_CURRENT_TOOL"
                  callbackView="Home"
                  callbackScreen="Main"
                />
              </View>
            )}
          <View style={{ marginVertical: 15 }}>
            <Pauses pauses={currentClocking.pauses?.filter((pause) => pause.end) || []} />
          </View>
        </View>
      </View>
    );

    let ShiftDetails: React.FC;
    let canEditClocking = false;

    if (isOnline) {
      canEditClocking = true;
    } else {
      const department = dataState.departments.find((department) => department.id == currentClocking.departmentId);
      if (department && department.clockinParams?.offlineClockings) {
        canEditClocking = true;
      }
    }

    if (currentClocking.shiftId) {
      const shift = dataState.shifts.find((shift) => shift.id === currentClocking.shiftId)!;
      const { start, end, pause, package: shiftPackage } = shift;
      const pauseDurationAsMinutes = moment.duration(pause!.paid! + pause!.unpaid!, 'seconds').asMinutes();

      // eslint-disable-next-line react/display-name
      ShiftDetails = () => (
        <View>
          <H1>{moment.unix(currentClocking.checkIn!.start!).format('LL')}</H1>
          <SIText style={{ fontSize: 24, textAlign: 'center' }}>
            {moment.unix(currentClocking.checkIn!.start!).format('HH:mm')}
          </SIText>
          <SIText style={styles.department}>{activeDepartment.company}</SIText>
          {shiftPackage && (
            <View style={[styles.packageContainer, { backgroundColor: shiftPackage.background }]}>
              <SIText style={[styles.package, { color: shiftPackage.color }]}>{shiftPackage.name}</SIText>
            </View>
          )}
          {!shiftPackage && (
            <View style={styles.details}>
              <SIText style={styles.detail}>{`${t('SHIFTS.START')} ${moment.unix(start!).format('HH:mm')}`}</SIText>
              <SIText style={styles.detail}>{`${t('SHIFTS.END')} ${moment.unix(end!).format('HH:mm')}`}</SIText>
              {pauseDurationAsMinutes > 0 && (
                <SIText style={styles.detail}>{`${t('CLOCKING.PAUSE')} ${minutesToHoursAndOrMinutes(
                  pauseDurationAsMinutes,
                )}`}</SIText>
              )}
            </View>
          )}
          {shift.section && (
            <View style={styles.tags}>
              <Tag
                key={`shift_${shift.id}_section_${shift.section.id}`}
                backgroundColor={shift.section.background}
                color={shift.section.color}
              >
                {shift.section.name || ''}
              </Tag>
            </View>
          )}
          {!!shift.skills?.length && (
            <View style={styles.tags}>
              {shift.skills.map((skill) => (
                <Tag key={`shift_${shift.id}_skill_${skill.id}`} backgroundColor={skill.background} color={skill.color}>
                  {skill.name || ''}
                </Tag>
              ))}
            </View>
          )}
          {!!shift.attributes?.length && (
            <View style={styles.tags}>
              {shift.attributes.map((attribute) => (
                <Tag
                  key={`shift_${shift.id}_attribute_${attribute.id}`}
                  backgroundColor={attribute.background}
                  color={attribute.color}
                >
                  {attribute.name || ''}
                </Tag>
              ))}
            </View>
          )}
        </View>
      );
    } else {
      const department = dataState.departments.find((department) => department.id == currentClocking.departmentId);
      const section =
        department && department.sections && currentClocking.sectionId
          ? department.sections.find((section) => section.id == currentClocking.sectionId)
          : null;
      const skill =
        department && department.skills && currentClocking.skillId
          ? department.skills.find((skill) => skill.id == currentClocking.skillId)
          : null;
      const attributes = department ? department.resources?.flatMap((resource) => resource.attributes) : null;

      // eslint-disable-next-line react/display-name
      ShiftDetails = () => (
        <View>
          <H1>{moment.unix(currentClocking.checkIn!.start!).format('DD MMMM YYYY')}</H1>
          <SIText style={{ fontSize: 24, textAlign: 'center' }}>
            {moment.unix(currentClocking.checkIn!.start!).format('HH:mm')}
          </SIText>
          {department && section && (
            <SIText
              style={{
                backgroundColor: section.background,
                color: getContrast(section.background!),
                textAlign: 'center',
                paddingVertical: 2,
                paddingHorizontal: 8,
                borderRadius: 8,
                marginBottom: 5,
                alignSelf: 'center',
              }}
              key={section.id}
            >
              {section?.name}
            </SIText>
          )}
          {department && skill && (
            <SIText
              style={{
                backgroundColor: skill.background,
                color: getContrast(skill.background!),
                textAlign: 'center',
                paddingVertical: 2,
                paddingHorizontal: 8,
                borderRadius: 8,
                marginBottom: 5,
                alignSelf: 'center',
              }}
              key={skill.id}
            >
              {skill?.name}
            </SIText>
          )}
          {department && attributes && currentClocking.attributes && (
            <View style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              {currentClocking.attributes.split(',').map((attr: string) => {
                const activeAttribute = attributes.find((attribute) => attribute?.id == attr);
                const background = activeAttribute && activeAttribute.background ? activeAttribute.background : '#fff';
                return (
                  <SIText
                    style={{
                      backgroundColor: background,
                      color: getContrast(background!),
                      textAlign: 'center',
                      paddingVertical: 2,
                      paddingHorizontal: 8,
                      borderRadius: 8,
                    }}
                    key={activeAttribute?.id}
                  >
                    {activeAttribute?.name}
                  </SIText>
                );
              })}
            </View>
          )}
          <SIText style={styles.department}>{activeDepartment.company}</SIText>
          <SIText style={[styles.department, { marginTop: -10 }]}>{activeDepartment.address}</SIText>
        </View>
      );
    }

    if (currentPause) {
      return (
        <SafeAreaView style={styles.container}>
          <ScrollView
            ref={ref}
            style={styles.scrollView}
            contentContainerStyle={[styles.contentContainerStyle, { padding: 25 }]}
          >
            <View style={{ flex: 1 }}>
              <View style={styles.containerTop}>
                <ShiftDetails />
                <Chronometer start={currentPause.start} color={colors.red} key="chrono_pause" />
                {/* <SIText style={styles.clockingTime}>{`${t('CLOCKING.CLOCKING_HOUR')} ${moment
                  .unix(currentClocking.checkIn!.start!)
                  .format('HH:mm')}`}</SIText>
                <SIText style={styles.clockingTime}>{`${t('CLOCKING.PAUSE_HOUR')} ${moment
                  .unix(currentPause!.start!)
                  .format('HH:mm')}`}</SIText> */}
                {activeDepartment &&
                  activeDepartment.clockinParams &&
                  activeDepartment.clockinParams.enableMobileClockin && (
                    <SIButton
                      title={t('CLOCKING.END_PAUSE')}
                      backgroundColor={colors.red}
                      onPress={onEndPause}
                      size="large"
                      style={{ paddingHorizontal: 10 }}
                    />
                  )}
              </View>
              <View style={{ marginTop: 25 }}>
                <OnlyClockin />
              </View>
            </View>
          </ScrollView>
        </SafeAreaView>
      );
    } else {
      return (
        <SafeAreaView style={styles.container}>
          {!canEditClocking && currentClocking && currentClocking.departmentId && (
            <NoOfflineDepartmentWarning departmentId={currentClocking.departmentId} />
          )}
          <ScrollView
            ref={ref}
            style={styles.scrollView}
            contentContainerStyle={[styles.contentContainerStyle, { padding: 25 }]}
          >
            <View style={styles.containerTop}>
              <ShiftDetails />
              <Chronometer start={currentClocking.checkIn?.start} key="chrono_worktime" />
              <View style={styles.containerBottom}>
                {isOnline &&
                  !!activeDepartment?.homeworking &&
                  activeDepartment &&
                  activeDepartment.clockinParams &&
                  activeDepartment.clockinParams.enableMobileClockin && (
                    <HomeWorking
                      clockingId={
                        currentClocking?.id || clockingState?.optimisticIdsToIds?.[currentClocking?.optimisticId || '']
                      }
                      afterWorkingHome={() => reduxDispatch({ type: 'CLOCKING_UPDATE_HOMEWORKING', payload: true })}
                      homeworking={!!currentClocking?.homeworking}
                      padding
                    />
                  )}
                {canEditClocking &&
                  activeDepartment &&
                  activeDepartment.clockinParams &&
                  activeDepartment.clockinParams.enableMobileClockin && (
                    <SIButton
                      title={t('CLOCKING.PAUSE')}
                      size="large"
                      style={{ marginBottom: 10, paddingHorizontal: 10 }}
                      onPress={onStartPause}
                    />
                  )}
                {canEditClocking &&
                  activeDepartment &&
                  activeDepartment.clockinParams &&
                  activeDepartment.clockinParams.enableMobileClockin && (
                    <SIButton
                      title={t('CLOCKING.CHECK_OUT')}
                      size="large"
                      backgroundColor={colors.red}
                      onPress={onCheckOut}
                      style={{ paddingHorizontal: 10 }}
                    />
                  )}
              </View>
            </View>
            {canEditClocking && <ClockingDetails />}
            {canEditClocking && <OnlyClockin />}
            {!canEditClocking && (
              <View style={{ marginTop: 25 }}>
                <OnlyClockin />
              </View>
            )}
          </ScrollView>
        </SafeAreaView>
      );
    }
  } else {
    if (dataState.clockinAccount) {
      return (
        <SafeAreaView style={styles.container}>
          <View style={styles.contentContainerStyle}>
            <Overview isOnline={isOnline} userDetails={authState.userDetails} withoutShortcuts />
            <NextShift shifts={[]} title={t('CLOCKING.NO_ONGOING_CLOCKING')} isOnline={isOnline} />
            <OnlyClockin padding />
          </View>
        </SafeAreaView>
      );
    } else {
      return (
        <Fragment>
          <StatusBar barStyle={isOnline ? 'dark-content' : 'light-content'} />
          <SafeAreaView
            style={{
              backgroundColor: isOnline ? colors.blueExtraLight : colors.red,
            }}
          />
          <SafeAreaView style={styles.container}>
            <ScrollView
              ref={ref}
              contentContainerStyle={[
                styles.contentContainerStyle,
                { backgroundColor: isOnline ? colors.blueExtraLight : colors.red },
              ]}
              refreshControl={
                <RefreshControl
                  onRefresh={refreshData}
                  refreshing={dataAreLoading || !nextPlannedShifts || !nextOpenShifts}
                  style={{ flexDirection: 'column', backgroundColor: isOnline ? colors.blueExtraLight : colors.red }}
                />
              }
            >
              {!isOnline &&
                dataState.no_offline_clockings_departments &&
                dataState.no_offline_clockings_departments.length > 0 && <OfflineClockingsWarning />}
              <Overview isOnline={isOnline} userDetails={authState.userDetails} indicators={indicators} />
              <NextShift shifts={filteredShifts} isOnline={isOnline} />
              {!!nextPlannedShifts && !!nextOpenShifts && (
                <View
                  style={{
                    padding: 25,
                    paddingTop: 0,
                    paddingBottom: 20,
                    width: '100%',
                    backgroundColor: colors.blueExtraLight,
                  }}
                >
                  <ShiftCards
                    title={dataState.fixed_view ? undefined : t('SHIFTS.PLANNED_SHIFTS')}
                    shifts={nextPlannedShifts}
                    noDataTitle={t('SHIFTS.NO_PLANNED_SHIFT')}
                    noDataMessage={t('SHIFTS.NO_PLANNED_SHIFT_MESSAGE')}
                  />
                  {!dataState.fixed_view && (
                    <ShiftCards
                      title={t('SHIFTS.FREE_SHIFTS')}
                      shifts={nextOpenShifts}
                      noDataTitle={t('SHIFTS.NO_FREE_SHIFT')}
                      noDataMessage={t('SHIFTS.NO_FREE_SHIFT_MESSAGE')}
                    />
                  )}
                </View>
              )}
            </ScrollView>
          </SafeAreaView>
        </Fragment>
      );
    }
  }
};

const styles = StyleSheet.create({
  containerTop: {
    padding: 10,
    paddingVertical: 25,
    backgroundColor: 'white',
    paddingBottom: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
    zIndex: 2,
    borderRadius: 10,
  },
  container: {
    backgroundColor: colors.blueExtraLight,
    display: 'flex',
    flex: 1,
  },
  scrollView: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    paddingTop: 5,
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
  detail: {
    fontSize: 11,
    textAlign: 'center',
    margin: 4,
  },
  clockingTime: {
    textAlign: 'center',
    color: colors.grey,
  },
  department: {
    fontSize: 11,
    textAlign: 'center',
    color: colors.grey,
    marginTop: 5,
    marginBottom: 5,
  },
  packageContainer: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 5,
    alignSelf: 'center',
    marginBottom: 10,
  },
  package: {
    fontFamily: 'Poppins_700Bold',
  },
  contentContainerStyle: {
    flexGrow: 1,
    backgroundColor: colors.blueExtraLight,
  },
});

export default HomeStack;

const NBR_OF_MAX_PLANNED_SHIFTS = 10;
