import { useFocusEffect } from '@react-navigation/native';
import { default as Axios, default as axios } from 'axios';
import Constants from 'expo-constants';
import * as ImagePicker from 'expo-image-picker';
import FormData from 'form-data';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import nl from 'i18n-iso-countries/langs/nl.json';
import { TFunction } from 'i18next';
import mime from 'mime';
import moment from 'moment';
import 'moment-timezone';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  Alert,
  Image,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Back from '../components/Back';
import DatePicker from '../components/DatePicker';
import Input from '../components/Input';
import SIButton from '../components/SIButton';
import SIText from '../components/SIText';
import Select from '../components/Select';
import { InitialState } from '../store';
import colors from '../styles/colors';
import { IClocking } from '../types/clocking.model';
import { IDepartment } from '../types/department.model';
import { isNullOrUndefined, minutesToHoursAndOrMinutes } from '../utils';
moment.tz.setDefault('Atlantic/Reykjavik');

countries.registerLocale(en);
countries.registerLocale(fr);
countries.registerLocale(nl);

interface IBasicInfos {
  email?: string;
  id?: string;
  totalMinutes?: number | string;
}

interface IMyDepartment extends IDepartment {
  overtime?: number;
  clockings?: IClocking[];
  userRecordId?: string;
}

interface IProfile {
  data: {
    profile: {
      avatar: string;
      email: string;
      first_name: string;
      last_name: string;
      name: string;
      personal_email: string;
      phone: string;
      preferred_language_code: string;
    };
    profile_extra: {
      bank_account_bic: string;
      bank_account_iban: string;
      birthdate: string;
      birthplace: string;
      civil_state: string;
      gender: string;
      national_number: string;
      nationality_country_code: string;
    };
    profile_address: {
      city: string;
      country_code: string;
      line_1: string;
      line_2: string;
      state: string;
      zipCode: string;
    };
  };
  meta: {
    civil_state: {
      id: string;
      name: string;
    }[];
    gender: {
      id: string;
      name: string;
    }[];
  };
  flags: {
    edit_email: boolean;
    edit_password: boolean;
  };
}

interface IItemProps {
  data: {
    overtime?: number;
    clockings: IClocking[];
  };
  t: TFunction;
}

const Item: React.FC<IItemProps> = ({ data, t }) => {
  const { overtime, clockings } = data;

  return (
    <View style={styles.item}>
      {!isNullOrUndefined(overtime) && overtime !== 0 && (
        <React.Fragment>
          <SIText>{t('GENERAL.OVERTIME_HOURS')}</SIText>
          <View style={[styles.itemOvertime, overtime! > 0 ? { backgroundColor: colors.red } : {}]}>
            <SIText style={[styles.itemOvertimeText, overtime! > 0 ? { color: 'white' } : {}]}>
              {minutesToHoursAndOrMinutes(overtime!)}
            </SIText>
          </View>
        </React.Fragment>
      )}
      {!!clockings?.length && (
        <View>
          <SIText style={{ marginTop: overtime ? 10 : 0, marginBottom: 5 }}>{t('GENERAL.CLOCKING_HISTORY')}</SIText>
          <View>
            {clockings.map((clocking, index) => (
              <View key={`clocking_${clocking.id}`} style={[styles.itemClocking, index ? { borderTopWidth: 1 } : null]}>
                <Text style={styles.itemClockingText}>
                  {clocking.checkIn ? `${moment.unix(clocking.checkIn.start!).format('L - HH:mm')}` : ``}
                </Text>
                <Text style={styles.itemClockingText}>
                  {clocking.checkOut ? `${moment.unix(clocking.checkOut.end!).format('L - HH:mm')}` : ``}
                </Text>
              </View>
            ))}
          </View>
        </View>
      )}
    </View>
  );
};

const MeScreen: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingProfile, setLoadingProfile] = useState<boolean>(false);
  const [departments, setDepartments] = useState<IMyDepartment[]>([]);
  const [basicInfos, setBasicInfos] = useState<IBasicInfos>({ email: '', id: '' });
  const [profile, setProfile] = useState<IProfile>();
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const authState = useSelector((store: InitialState) => store.auth, shallowEqual);
  const dataState = useSelector((store: InitialState) => store.data, shallowEqual);
  const { t, i18n } = useTranslation(undefined, { useSuspense: false });

  const refreshData = () => {
    if (!isLoading) {
      const cancelTokenSource = Axios.CancelToken.source();
      setIsLoading(true);
      Axios.get(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/me`,
        {
          params: {
            mobile: true,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
        .then((response) => {
          const { departments, email, id } = response.data;
          setDepartments(departments);
          setBasicInfos({ email, id });
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (departments.length > 0) {
      const department = departments[0];
      const recordId = department.userRecordId;
      const cancelTokenSource = Axios.CancelToken.source();
      Axios.get(
        `${
          Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
        }/v3/users/${recordId}/profile`,
        {
          params: {
            mobile: true,
            departmentId: department.id,
          },
          cancelToken: cancelTokenSource.token,
        },
      )
        .then((response) => {
          setProfile(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  }, [departments]);

  useFocusEffect(
    useCallback(() => {
      refreshData();
    }, []),
  );

  const { handleSubmit, control, watch, setValue } = useForm({ shouldUnregister: false });
  const [currentDate, setCurrentDate] = useState<any>(null);

  useEffect(() => {
    if (profile?.data?.profile_extra?.birthdate) {
      setCurrentDate(profile.data.profile_extra.birthdate);
    }
  }, [profile]);

  const sorted_countries = Object.entries(countries.getNames(i18n.language)).sort((a, b) =>
    a[1].toLowerCase() < b[1].toLowerCase() ? -1 : 1,
  );

  useEffect(() => {
    if (profile) {
      const { data, meta, flags } = profile || {};
      const { profile: profile_default, profile_address, profile_extra } = data || {};

      setValue('profile.last_name', profile_default.last_name);
      setValue('profile.first_name', profile_default.first_name);
      setValue('profile.preferred_language_code', profile_default.preferred_language_code.toString());
      setValue('profile.email', profile_default.email);
      setValue('profile.phone', profile_default.phone);

      setValue('profile_address.line_1', profile_address.line_1);
      setValue('profile_address.line_2', profile_address.line_2);
      setValue('profile_address.zipCode', profile_address.zipCode);
      setValue('profile_address.city', profile_address.city);
      setValue('profile_address.state', profile_address.state);
      setValue('profile_address.country_code', profile_address.country_code);

      setValue('profile_extra.national_number', profile_extra.national_number);
      setValue('profile_extra.civil_state', profile_extra.civil_state);
      setValue('profile_extra.birthdate', profile_extra.birthdate ? new Date(profile_extra.birthdate) : new Date());
      setValue('profile_extra.birthplace', profile_extra.birthplace);
      setValue('profile_extra.gender', profile_extra.gender);
      setValue('profile_extra.nationality_country_code', profile_extra.nationality_country_code);
      setValue('profile_extra.bank_account_iban', profile_extra.bank_account_iban);
      setValue('profile_extra.bank_account_bic', profile_extra.bank_account_bic);
    }
  }, [profile]);

  const onSubmit = (data: any) => {
    setLoadingProfile(true);
    const cancelTokenSource = Axios.CancelToken.source();
    Axios.patch(
      `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/users/${
        departments[0].userRecordId
      }/profile`,
      data,
      {
        params: {
          departmentId: departments[0].id,
          mobile: true,
        },
        cancelToken: cancelTokenSource.token,
      },
    )
      .then((response) => {
        showMessage({
          message: t('PROFILE.SAVED_MESSAGE'),
          type: 'success',
        });
        setProfile(response.data);
        setLoadingProfile(false);
        if (showDatePicker) {
          setShowDatePicker(false);
        }
      })
      .catch((err) => {
        if (Platform.OS === 'web') {
          alert(`${t('GENERAL.ERROR')}`);
        } else {
          Alert.alert(t('GENERAL.ERROR'));
        }
        setLoadingProfile(false);
      });
  };

  const reduxDispatch: Dispatch<any> = useDispatch();
  const [imagePickerAccess, setImagePickerAccess] = useState(false);
  const [changePictureLoading, setChangePictureLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status == 'granted') {
          setImagePickerAccess(true);
        }
      } else {
        setImagePickerAccess(true);
      }
    })();
  }, []);

  const onChangePicture = async () => {
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 0.5,
        selectionLimit: 1,
        base64: true,
      });

      setChangePictureLoading(true);

      if (!result.cancelled) {
        const formData = new FormData();

        if (Platform.OS == 'web') {
          const image = await fetchImageFromUri(result.uri);
          formData.append('file', image);
        }

        const newImageUri = 'file:///' + result.uri.split('file:/').join('');

        formData.append('file', {
          uri: newImageUri,
          type: mime.getType(newImageUri),
          name: newImageUri.split('/').pop(),
        });

        for (let i = 0; i < departments.length; i++) {
          const department = departments[i];

          axios
            .post(
              `${
                Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL
              }/v3/users/${department.userRecordId}/picture`,
              formData,
              {
                params: {
                  departmentId: department.id,
                },
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              },
            )
            .then(({ data }) => {
              reduxDispatch({
                type: 'AUTH_SET_USER_DETAILS',
                payload: {
                  ...authState.userDetails,
                  picture: data.url,
                },
              });
              showMessage({
                message: t('PROFILE.PICTURE_CHANGED_SUCCESS'),
                type: 'success',
              });
              setChangePictureLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setChangePictureLoading(false);
              showMessage({
                message: t('PROFILE.CHANGE_PICTURE_ERROR'),
                type: 'danger',
              });
            });
        }
      } else {
        setChangePictureLoading(false);
      }
    } catch (err) {
      setChangePictureLoading(false);
    }
  };

  const onRemoveProfilePicture = () => {
    axios
      .delete(
        `${Constants.manifest?.extra?.API_URL || Constants.manifest2?.extra?.expoClient?.extra?.API_URL}/v3/users/${
          departments[0].userRecordId
        }/picture`,
        {
          params: {
            departmentId: departments[0].id,
          },
        },
      )
      .then(({ data }) => {
        reduxDispatch({
          type: 'AUTH_SET_USER_DETAILS',
          payload: {
            ...authState.userDetails,
            picture: data.url,
          },
        });
        showMessage({
          message: t('PROFILE.PICTURE_DELETED_SUCCESS'),
          type: 'success',
        });
        setChangePictureLoading(false);
      })
      .catch((err) => {
        setChangePictureLoading(false);
        showMessage({
          message: t('PROFILE.DELETE_PICTURE_ERROR'),
          type: 'danger',
        });
      });
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.blueExtraLight }}>
      <Back
        title={t('GENERAL.MY_INFORMATION')}
        icons={
          <SIButton title={t('GENERAL.CONFIRM')} loading={loadingProfile} onPress={handleSubmit(onSubmit)}></SIButton>
        }
      />
      <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
        <View style={styles.content}>
          <View style={{ padding: 25 }}>
            <>
              {isLoading ? (
                <ActivityIndicator size="large" color={colors.green} />
              ) : (
                <>
                  {profile && (
                    <>
                      {dataState.access_clocking_departments && dataState.access_clocking_departments.length > 0 && (
                        <View>
                          <SIText style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 15 }}>
                            {t('PROFILE.PIN_CODE')}: {authState.userDetails?.pinCode}
                          </SIText>
                        </View>
                      )}
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginBottom: 15,
                        }}
                      >
                        <View style={{ marginRight: 10 }}>
                          <SIText>{t('PROFILE.PROFILE_PICTURE_TITLE')}</SIText>
                          {authState.userDetails?.picture ? (
                            <TouchableOpacity onPress={onRemoveProfilePicture}>
                              <SIText style={{ textDecoration: 'underline' }}>{t('GENERAL.REMOVE')}</SIText>
                            </TouchableOpacity>
                          ) : null}
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <TouchableOpacity
                            onPress={onChangePicture}
                            style={{ flexDirection: 'row', alignItems: 'center' }}
                          >
                            {authState.userDetails?.picture && (
                              <Image
                                style={styles.avatar}
                                source={{
                                  uri: authState.userDetails?.picture,
                                }}
                              />
                            )}
                            {!authState.userDetails?.picture && (
                              <View style={[styles.avatar, styles.initials]}>
                                <SIText style={{ color: 'white' }}>{authState.userDetails?.initials}</SIText>
                              </View>
                            )}
                          </TouchableOpacity>
                          {changePictureLoading && (
                            <ActivityIndicator size="small" color={colors.green} style={{ marginLeft: 10 }} />
                          )}
                        </View>
                      </View>
                      <Input
                        defaultValue={null}
                        label={t('GENERAL.LAST_NAME')}
                        placeholder={t('GENERAL.LAST_NAME')}
                        control={control}
                        name="profile.last_name"
                      />
                      <Input
                        defaultValue={null}
                        label={t('GENERAL.FIRST_NAME')}
                        placeholder={t('GENERAL.FIRST_NAME')}
                        control={control}
                        name="profile.first_name"
                      />
                      <Select
                        label={t('GENERAL.LANGUAGE')}
                        placeholder={t('GENERAL.LANGUAGE')}
                        control={control}
                        defaultValue={null}
                        name="profile.preferred_language_code"
                        items={[
                          {
                            label: 'Français',
                            value: '1',
                          },
                          {
                            label: 'Nederlands',
                            value: '2',
                          },
                          {
                            label: 'English',
                            value: '3',
                          },
                          {
                            label: 'Español',
                            value: '4',
                          },
                        ]}
                      />
                      <Input
                        defaultValue={null}
                        label={t('GENERAL.EMAIL')}
                        placeholder={t('GENERAL.EMAIL')}
                        control={control}
                        name="profile.email"
                        keyboardType="email-address"
                        editable={false}
                      />
                      <Input
                        defaultValue={null}
                        label={t('GENERAL.PHONE')}
                        placeholder={t('GENERAL.PHONE')}
                        control={control}
                        name="profile.phone"
                        keyboardType="phone-pad"
                      />
                      <SIText style={styles.title}>{t('PROFILE.ADDRESS_TITLE')}</SIText>
                      <Input
                        defaultValue={null}
                        label={t('GENERAL.ADDRESS_LINE_1')}
                        placeholder={t('GENERAL.ADDRESS_LINE_1')}
                        control={control}
                        name="profile_address.line_1"
                      />
                      <Input
                        defaultValue={null}
                        label={t('GENERAL.ADDRESS_LINE_2')}
                        placeholder={t('GENERAL.ADDRESS_LINE_2')}
                        control={control}
                        name="profile_address.line_2"
                      />
                      <Input
                        defaultValue={null}
                        label={t('GENERAL.ZIPCODE')}
                        placeholder={t('GENERAL.ZIPCODE')}
                        control={control}
                        name="profile_address.zipCode"
                      />
                      <Input
                        defaultValue={null}
                        label={t('GENERAL.CITY')}
                        placeholder={t('GENERAL.CITY')}
                        control={control}
                        name="profile_address.city"
                      />
                      <Input
                        defaultValue={null}
                        label={t('GENERAL.STATE')}
                        placeholder={t('GENERAL.STATE')}
                        control={control}
                        name="profile_address.state"
                      />
                      <Select
                        label={t('GENERAL.COUNTRY')}
                        placeholder={t('GENERAL.COUNTRY')}
                        control={control}
                        defaultValue={null}
                        name="profile_address.country_code"
                        items={sorted_countries.map((country) => {
                          return {
                            label: country[1],
                            value: country[0],
                          };
                        })}
                      />
                      <SIText style={styles.title}>{t('PROFILE.EXTRA_TITLE')}</SIText>
                      <Input
                        defaultValue={null}
                        label={t('GENERAL.NATIONAL_NUMBER')}
                        placeholder={t('GENERAL.NATIONAL_NUMBER')}
                        control={control}
                        name="profile_extra.national_number"
                        editable={profile.data.profile_extra.national_number == ''}
                      />
                      <View
                        style={{
                          marginTop: 5,
                          marginBottom: 15,
                          padding: 10,
                          borderWidth: 1,
                          borderColor: colors.greyLight,
                          borderRadius: 10,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <View style={{ flex: 1 }}>
                          <SIText style={{ fontSize: 12, color: colors.grey }}>{t('GENERAL.BIRTHDATE')}</SIText>
                          <TouchableOpacity onPress={() => setShowDatePicker(!showDatePicker)} style={{ flex: 1 }}>
                            <SIText>{currentDate ? moment(currentDate).format('L') : t('PROFILE.SELECT_DATE')}</SIText>
                          </TouchableOpacity>
                        </View>
                        <DatePicker
                          style={{ flex: 1 }}
                          control={control}
                          name="profile_extra.birthdate"
                          mode="date"
                          show={showDatePicker}
                          defaultValue={currentDate && moment(currentDate).unix()}
                          setShow={() => setShowDatePicker(!showDatePicker)}
                          setDate={setCurrentDate}
                        />
                      </View>
                      <Input
                        defaultValue={null}
                        label={t('GENERAL.BIRTHPLACE')}
                        placeholder={t('GENERAL.BIRTHPLACE')}
                        control={control}
                        name="profile_extra.birthplace"
                      />
                      <Select
                        label={t('GENERAL.CIVIL_STATE')}
                        placeholder={t('GENERAL.CIVIL_STATE')}
                        control={control}
                        defaultValue={null}
                        name="profile_extra.civil_state"
                        items={profile.meta.civil_state.map((state) => {
                          return {
                            label: state.name,
                            value: state.id,
                          };
                        })}
                      />
                      <Select
                        label={t('GENERAL.GENDER')}
                        placeholder={t('GENERAL.GENDER')}
                        control={control}
                        defaultValue={null}
                        name="profile_extra.gender"
                        items={profile.meta.gender.map((gender) => {
                          return {
                            label: gender.name,
                            value: gender.id,
                          };
                        })}
                      />
                      <Select
                        label={t('GENERAL.NATIONALITY_COUNTRY')}
                        placeholder={t('GENERAL.NATIONALITY_COUNTRY')}
                        control={control}
                        defaultValue={null}
                        name="profile_extra.nationality_country_code"
                        items={sorted_countries.map((country) => {
                          return {
                            label: country[1],
                            value: country[0],
                          };
                        })}
                      />
                      <Input
                        defaultValue={null}
                        label="IBAN"
                        placeholder="BIC"
                        control={control}
                        name="profile_extra.bank_account_iban"
                      />
                      <Input
                        defaultValue={null}
                        label="BIC"
                        placeholder="BIC"
                        control={control}
                        name="profile_extra.bank_account_bic"
                      />
                    </>
                  )}
                  {departments
                    .filter(
                      (x) =>
                        dataState.departments.find((dept) => dept.id == x.id)?.params?.showExtraHours !== 0 ||
                        !!x.clockings?.length,
                    )
                    .map((department) => {
                      return (
                        <View key={department.id} style={{ marginTop: 10 }}>
                          <SIText>{department.company}</SIText>
                          <Item data={{ clockings: department.clockings || [], overtime: department.overtime }} t={t} />
                        </View>
                      );
                    })}
                </>
              )}
            </>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const fetchImageFromUri = async (uri: any) => {
  const response = await fetch(uri);
  const blob = await response.blob();
  return blob;
};

const styles = StyleSheet.create({
  profile_container: {
    backgroundColor: '#fff',
    padding: 25,
    borderRadius: 10,
  },
  title: {
    marginBottom: 10,
    color: colors.green,
    fontWeight: 'bold',
    fontSize: 18,
  },
  container: {
    display: 'flex',
    padding: 25,
    backgroundColor: colors.blueExtraLight,
  },
  content: {
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  header: {
    backgroundColor: '#fff',
    marginBottom: 15,
    padding: 15,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  email: {
    fontSize: 14,
  },
  id: {
    fontSize: 12,
    color: colors.grey,
  },
  item: {
    paddingVertical: 15,
  },
  itemClocking: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderColor: colors.greyLight,
    paddingVertical: 5,
  },
  itemClockingText: {
    fontSize: 14,
    fontVariant: ['tabular-nums'],
  },
  itemOvertime: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    backgroundColor: colors.greenExtraLight,
    borderColor: colors.greenLight,
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginTop: 5,
    borderRadius: 5,
  },
  itemOvertimeText: {
    fontSize: 16,
    flexGrow: 0,
    color: colors.green,
    display: 'flex',
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  initials: {
    backgroundColor: colors.blue,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export { styles };
export default MeScreen;
